import React,{Component} from 'react';
import {Label,Row,Col} from "reactstrap";
import window from '../../../assets/icon/windows-no-red.png';
import cycling from '../../../assets/icon/cycling-no-red.png';
import mask from '../../../assets/icon/mask.png';
import air from '../../../assets/icon/air-purifier-no-green.png';

import './style.scss';

class PrecautionCard extends Component{


    render() {

        let{lg}=this.props;
        let dataSet=[];

        if (this.props.activityList !== undefined && this.props.activityList.length !== 0){
            dataSet=this.props.activityList;
        }

        return (
            <div className={'precaution-card'}>
                {/*<Label className={'main-lbl precaution-heading-txt'}>{this.props.lang.precaution_heading}</Label>*/}
                <div className={'main-div'}>
                    <div className={"md-12"}>
                        <Label className={''}>{this.props.lang.precaution_heading}</Label>
                    </div>
                    <div className={'main-div-inner'}>
                        {dataSet.map(item=>{
                            if (item.symbol !== 3){
                                return <div key={item.symbol} className={'row m-0 p-0'} style={{marginBottom:'8px'}}>
                                    <div className={'col-2 m-0 p-0'}>
                                        <img src={item.image} className={'img-div'}/>
                                    </div>
                                    <div className={'col-10 m-0 p-0 ver-center'} style={{margin:'auto'}}>
                                        <Label className={'sub-lbl'}>{lg === "en" ? item.info : lg === "si" ? item.info_sin : item.info_tamil}</Label>
                                    </div>
                                </div>
                            }
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

export default PrecautionCard;

import React, { Component } from 'react'
import logo from '../../../assets/logo/Vaayu Logo/Logo.png'
import { Label } from "reactstrap";
import './style.scss'
import * as Icon from 'react-feather';

class Footer extends Component {
    render() {
        return (
            <div className={'footer-div'}>
                <div><img src={logo} /></div>
                <div><Label className="title">{this.props.lang.breathe}</Label></div>
                {/* <div style={{ display: 'inline-flex' }}>
                    <p className={'mail1-p'}><Icon.Mail className={'mail-icon'} />vaayutech8@gmail.com (Tech-related)</p>
                    <p className={'mail2-p'}><Icon.Mail className={'mail-icon'} />vaayu.nbro@gmail.com (Air quality Related)</p>
                </div> */}
                <div style={{ display: 'inline-flex' }}>
                    <div style={{ flexDirection: 'column' }}>
                        <p className={'mail1-p'}><Icon.Mail className={'mail-icon'} />vaayutech8@gmail.com</p>
                        <p style={{ marginTop: '-15px' }}>{this.props.lang.tech_related}</p>
                    </div>
                    <div style={{ flexDirection: 'column' }}>
                        <p className={'mail2-p'}><Icon.Mail className={'mail-icon'} />vaayu.nbro@gmail.com</p>
                        <p style={{ marginTop: '-15px', marginBottom: '1rem' }}>{this.props.lang.air_quality_related}</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;

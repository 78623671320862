import ApiService from './apiService';

export async function getDevice(language, deviceId) {
    const apiObject = {};
    apiObject.method = 'GET';
    apiObject.basePath = true;
    apiObject.endpoint =  `${language}/device/${deviceId}`;
    apiObject.body = null;
    return await ApiService(apiObject);
}

export async function getLocation(location){
    const apiObject = {};
    apiObject.method = 'GET';
    apiObject.basePath = true;
    apiObject.endpoint =  `location?lang=${location.lang}&lat=${location.lat}&long=${location.long}`;
    apiObject.body = null;
    return await ApiService(apiObject);
}

export async function getChartData(deviceId, parameter) {
    const apiObject = {};
    apiObject.method = 'GET';
    apiObject.basePath = true;
    apiObject.endpoint = `chart/${deviceId}/${parameter}`;
    apiObject.body = null;
    return await ApiService(apiObject);
}

export async function sendDevice(body) {
    const apiObject = {};
    apiObject.method = 'POST';
    apiObject.basePath = true;
    apiObject.endpoint = `notification`;
    apiObject.body = body;
    return await ApiService(apiObject);
}
// https://vaayu.lk/api/subscriptions
export async function checkSubscription(body) {
    const apiObject = {};
    apiObject.method = 'POST';
    apiObject.basePath = true;
    apiObject.endpoint = `subscriptions`;
    apiObject.body = body;
    return await ApiService(apiObject);
}

// export async function sendDeviceLocation() {
//     const apiObject = {};
//     apiObject.method = 'POST';
//     apiObject.basePath = true;
//     apiObject.endpoint = `data/e3b0c44298fc1c149afbf4c8996fb92427ae41e4649b934ca495991b7852b85/3379414009722521`;
//     apiObject.body = null;
//     return await ApiService(apiObject);
// }


export async function getLocationAqiColor() {
    const apiObject = {};
    apiObject.method = 'GET';
    apiObject.basePath = true;
    apiObject.endpoint = `map/en/getLocationAqiColor`;
    apiObject.body = null;
    return await ApiService(apiObject);
}

export async function getNewChartData(deviceId) {
    const apiObject = {};
    apiObject.method = 'GET';
    apiObject.basePath = true;
    apiObject.endpoint = `chart/getChartData?device_id=${deviceId}`;
    apiObject.body = null;
    return await ApiService(apiObject);
}


import React, { Component } from 'react';
import { Modal } from 'antd';
import './style.scss';
import "chartjs-plugin-annotation";
import * as interfaceService from "../../../services/interface";
import moment from 'moment';
import Loader from '../loader/Loader';
import * as graphData from '../../../utility/DateGenerator';
import LineChart from "../line-chart/LineChart";
import ChartLegend from "../chart-legend/ChartLegend"
import * as config from '../../../config'
import {ENGLISH, TEMPERATURE_TYPE} from "../../../config";
import * as dateGenerator from "../../../utility/DateGenerator";
const langText = require('../../lang/language.json');


class MoreDetail extends Component {


    state = {
        label_set: [],
        data_set: [],
        loading: false,
    };


    componentDidMount() {
        this.getGraphData();
    }

    handleCancel = () => {
        this.props.close({});
    };


    getGraphData = () => {
        this.setState({ loading: true });
        interfaceService.getChartData(this.props.deviceId, this.props.detail.symbol)
            .then(response => {
                if (response.avgValues.length !== 0) {
                    let temp_label = [];
                    let temp_data = [];

                    if (response.avgValues.length <= 3) {
                        let arrayLength = response.avgValues.length
                        let num = 7 - arrayLength
                        for (let i = num; i > 0; i--) {
                            temp_data.push(0.00)
                            temp_label.push(moment(new Date(response.avgValues[arrayLength - 1].updated_at.substring(0, 10))).subtract(i, 'days').format('DD/MM/YYYY'))
                        }
                    }



                    response.avgValues.map(item => {
                        // temp_label.push(this.props.lang[moment(item.date).format('ddd').toString()])
                        // temp_label.push(moment(item.date).format("MMM Do YY"))
                        temp_label.push(moment(item.date).format("DD/MM/YYYY"))
                        temp_data.push(item.avg.toFixed(2))
                    })

                    this.setState({
                        label_set: temp_label,
                        data_set: temp_data
                    })
                } else {
                    let result = graphData.getLast7Days();
                    this.setState({
                        label_set: result,
                    })
                }

                // if(status !== "focus"){
                //     this.props.firebaseAnalyticsHandler(firebaseConstants.FIREBASE_EVENT_TILE_CLICK, obj.symbol , obj.name , obj.symbol);
                //     this.props.test(false);
                // }
                this.setState({ loading: false });
            })
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            })
    };


    render() {
        let {data_set,label_set} = this.state;
        let days = dateGenerator.getLast7Days();
        let finalizeData = [];
        let finalizeDates = [];

        if(label_set.length > 0) {
            let dates = label_set;
            let bodyData = data_set;
            days.map((obj,index)=>{
                let tempIndex = dates.findIndex(dateObj => dateObj === obj);
                finalizeDates.push(obj);
                finalizeData.push(tempIndex !== -1 ? bodyData[tempIndex] : 0);
                // data
            })
            // data.days.map((obj,index) => {
            //
            // })
        }

        const lan = localStorage.getItem("lang") ? localStorage.getItem("lang") : ENGLISH;
        return (
            <Modal
                title={<label className={'title-modal'}>{this.props.detail.name}{lan === config.ENGLISH ? "" : lan === config.SINHALA && this.props.detail.name !== "උෂ්ණත්වය" ? "(අංශු)" : ""}</label>}
                visible={this.props.visibility}
                onCancel={this.handleCancel}
                okButtonProps={{ style: { display: 'none' } }}
                cancelButtonProps={{ style: { display: 'none' } }}
                width={1000}
            >

                {
                    this.state.loading ? <Loader lang={this.props.lang} /> :
                        <div className={'graph-div'}>
                            {/*<LineChart data={this.state.data_set} days={this.state.label_set} type={"not_default"} chartType={this.props.detail.symbol} yAxisName={this.props.detail.name} />*/}
                            <LineChart data={finalizeData} days={finalizeDates} type={"not_default"} chartType={this.props.detail.symbol} yAxisName={`${this.props.detail.name} (${config.TEMPERATURE_TYPE === this.props.detail.symbol ? `°C`:`μg/m3`})`} />
                        </div>
                }

                <br />
                <div className={'more-info'}>
                    <ChartLegend chartType={this.props.detail.symbol} />
                </div>
                <br />

                <div>
                    {/*<p>{this.props.detail.info}</p>*/}
                    <h5>{config.PM2_TYPE === this.props.detail.symbol ? langText[lan].pm25_info : config.PM10_TYPE === this.props.detail.symbol ? langText[lan].pm10_info : ''}</h5>
                </div>
                <br />
                {
                    config.PM2_TYPE === this.props.detail.symbol ?
                        <div>
                            <div>
                                <h5>{langText[lan].chart_pm2_heading_01}</h5>
                            </div>
                            <div>
                                <ul>
                                    <li>{langText[lan].heading_01_point_01}</li>
                                    <li>{langText[lan].heading_01_point_02}</li>
                                    <li>{langText[lan].heading_01_point_03}</li>
                                    <li>{langText[lan].heading_01_point_04}</li>
                                    <li>{langText[lan].heading_01_point_05}</li>
                                </ul>
                            </div>
                            <br />
                            <div>
                                <h5>{langText[lan].chart_pm2_heading_02}</h5>
                                <div>
                                    <ul>
                                        <li>{langText[lan].heading_02_point_01}</li>
                                        <li>{langText[lan].heading_02_point_02}</li>
                                        <li>{langText[lan].heading_02_point_03}</li>
                                        <li>{langText[lan].heading_02_point_04}</li>
                                    </ul>
                                </div>
                            </div>
                            <br />
                            <div>
                                <h5>{langText[lan].chart_pm2_heading_03}</h5>
                                <div>
                                    <ul>
                                        <li>{langText[lan].heading_03_point_01}</li>
                                        <li>{langText[lan].heading_03_point_02}</li>
                                        <li>{langText[lan].heading_03_point_03}</li>
                                        <li>{langText[lan].heading_03_point_04}</li>
                                        <li>{langText[lan].heading_03_point_05}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        :
                        config.PM10_TYPE === this.props.detail.symbol ?
                            <div>
                                <div>
                                    <h5>{langText[lan].chart_pm10_heading_01}</h5>
                                    <div>
                                        <ul>
                                            <li>{langText[lan].pm10_heading_01_point_01}</li>
                                            <li>{langText[lan].pm10_heading_01_point_02}</li>
                                            <li>{langText[lan].pm10_heading_01_point_03}</li>
                                            <li>{langText[lan].pm10_heading_01_point_04}</li>
                                            <li>{langText[lan].pm10_heading_01_point_05}</li>
                                            <li>{langText[lan].pm10_heading_01_point_06}</li>
                                            <li>{langText[lan].pm10_heading_01_point_07}</li>
                                        </ul>
                                    </div>
                                </div>

                                <br />

                                <div>
                                    <h5>{langText[lan].chart_pm10_heading_02}</h5>
                                    <div>
                                        <ul>
                                            <li>{langText[lan].pm10_heading_02_point_01}</li>
                                            <li>{langText[lan].pm10_heading_02_point_02}</li>
                                            <li>{langText[lan].pm10_heading_02_point_03}</li>
                                            <li>{langText[lan].pm10_heading_02_point_04}</li>
                                            {/*<li>{langText[lan].pm10_heading_02_point_05}</li>
                                            <li>{langText[lan].pm10_heading_02_point_06li>*/}
                                        </ul>
                                    </div>
                                    <h5>{langText[lan].chart_pm10_heading_03}</h5>
                                    <div>
                                        <ul>
                                            <li>{langText[lan].pm10_heading_03_point_01}</li>
                                            <li>{langText[lan].pm10_heading_03_point_02}</li>
                                            <li>{langText[lan].pm10_heading_03_point_03}</li>
                                        </ul>
                                    </div>
                                    <h5>{langText[lan].chart_pm10_heading_04}</h5>
                                    <div>
                                        <ul>
                                            <li>{langText[lan].pm10_heading_04_point_01}</li>
                                            <li>{langText[lan].pm10_heading_04_point_02}</li>
                                            <li>{langText[lan].pm10_heading_04_point_03}</li>
                                            <li>{langText[lan].pm10_heading_04_point_04}</li>
                                            <li>{langText[lan].pm10_heading_04_point_05}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            : null
                }
            </Modal>
        );
    }
}

export default MoreDetail;

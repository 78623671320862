import React, { Component } from 'react';
import * as config from '../../../config'
import './style.scss'
import {ENGLISH} from "../../../config";

const langText = require('../../lang/language.json');
class ChartLegend extends Component {
    render() {
        const lan = localStorage.getItem("lang") ? localStorage.getItem("lang") : ENGLISH;
        return (
            <div className='legend-content'>
                {
                    this.props.chartType === config.TEMPERATURE_TYPE ? [...config.ANNOTATION_TEMPERATURE].reverse().map((val,index) => {
                        return (
                            <p key={index} className='list-item'><p className={`legend-name-tg tg-temp legend-name-tg-temp-${index}`}>{val.lang_name ? langText[lan][val.lang_name] : val.yMin + " - " + val.yMax}</p>{<p className={"m-0"}>&nbsp;&nbsp;</p>}{<div style={{ width: window.screen.width < window.screen.height ? 20 : 30, height: window.screen.width < window.screen.height ? 10 : 20, backgroundColor: val.backgroundColor }}></div>}</p>
                        )
                    }) :
                        this.props.chartType === config.PM2_TYPE ? [...config.ANNOTATION_PM2].reverse().map((val,index) => {
                            return (
                                <p key={index} className='list-item'><p className={`legend-name-tg legend-name-tg-${index}`}>{val.lang_name ? langText[lan][val.lang_name] : val.yMin + " - " + val.yMax}</p>{<p className={"m-0"}>&nbsp;&nbsp;</p>}{<div style={{ width: window.screen.width < window.screen.height ? 20 : 30, height: window.screen.width < window.screen.height ? 10 : 20, backgroundColor: val.background }}></div>}</p>
                            )
                        }) :
                            this.props.chartType === config.PM10_TYPE ? [...config.ANNOTATION_PM10].reverse().map((val,index) => {
                                return (
                                    <p key={index} className='list-item'><p className={`legend-name-tg legend-name-tg-${index}`}>{val.lang_name ? langText[lan][val.lang_name] : val.yMin + " - " + val.yMax}</p>{<p className={"m-0"}>&nbsp;&nbsp;</p>}{<div style={{ width: window.screen.width < window.screen.height ? 20 : 30, height: window.screen.width < window.screen.height ? 10 : 20, backgroundColor: val.background }}></div>}</p>
                                )
                            }) :
                                [...config.DEFAULT_ANNOTATION].reverse().map((val,index) => {
                                    return (
                                        <p key={index} className='list-item'><p className={`legend-name-tg legend-name-tg-${index}`}>{val.lang_name ? langText[lan][val.lang_name] : val.yMin + " - " + val.yMax}</p>{<p className={"m-0"}>&nbsp;&nbsp;</p>}{<div style={{ width: window.screen.width < window.screen.height ? 20 : 30, height: window.screen.width < window.screen.height ? 10 : 20, backgroundColor: val.background }}></div>}</p>
                                    )
                                })
                }
            </div>
        );
    }
}

export default ChartLegend;

import React,{Component} from 'react';
import {Label} from "reactstrap";
import Map from '../../GoogleMap/Googlemap';

import './style.scss';
import Loader from "../loader/Loader";

const alltexts = require('../../lang/language.json');

class AqiMap extends Component{

    state={
        mapCenter: {
            lat: 6.927079,
            lng: 79.861244,
        },
        loading:true
    };

    handleLoading=()=>{
        this.setState({loading:false})
    }

    render() {

        let {lg}=this.props;

        return (
            <div className={'aqi-map-card'}>
                {/*<Label className={'main-lbl'} >{this.props.lang.map_heading}</Label>*/}
                <div className={'main-div'}>
                    {
                        this.state.loading &&
                        <div className={'map-loader'}>
                            <Loader lang={''}/>
                        </div>
                    }
                    <Map lang= {'en'} lg={alltexts.en} locations={this.props.locationsList} locationId={this.props.locationId}
                         getMarkerId={this.props.getMarkerId} handleChartChange={this.handleChartChange}
                         loadingAction={this.handleLoading}/>
                </div>
            </div>
        );
    }
}

export default AqiMap;
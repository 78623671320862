import React,{Component} from 'react';

class Loader extends Component{
    render() {
        return (
            <div align="center" style={{marginTop:'20px'}}>
                <h6 style={{ fontWeight:600 }}>{this.props.lang === '' ? '' : this.props.lang.wait}</h6>
                <div className="loader-vaayu" />
            </div>
        );
    }
}

export default Loader;
import React, { Component } from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import './googleMaps.scss';
// import { Dropdown } from 'semantic-ui-react';
import MapProperties from './DireactionProperties'
import map from '../../assets/icon/map.png';
import { Card } from 'reactstrap';
import * as firebaseConstants from "../../config";
import * as interfaceService from "../../services/interface";
import excellent from '../../assets/icon/excellent.png'
import good from '../../assets/icon/good.png'
import moderate from '../../assets/icon/moderate.png'
import poor from '../../assets/icon/poor.png'
import veryPoor from '../../assets/icon/veryPoor.png'
import severe from '../../assets/icon/severe.png'

export class MapContainer extends Component {

    state = {
        address: '',
        showingInfoWindow: false,
        activeMarker: {},
        selectedPlace: {},
        selected: '',
        locationList: [],
    };

    componentDidMount() {
        this.getLocationList();
    }

    getLocationList = async () => {

        let temp = []

        await interfaceService.getLocationAqiColor().then(async response => {
            if (response !== undefined) {
                response.location_aqi_data.map(location => {
                    if (location.device_info.length > 0) {
                        temp.push({
                            location: location.device_info[0].location,
                            longitude: location.device_info[0].longitude,
                            latitude: location.device_info[0].latitude,
                            id: location.device_id,
                            aqiLabel: location.aqiLabel
                        })
                    }
                })
            }

            await this.setState({ locationList: temp })
            this.props.loadingAction();
        }).catch(error => {
        })
    }


    onMarkerClick = (value, firebaseEvent) => {
        this.props.getMarkerId(value, firebaseEvent);
    };

    // handleDropdownChange = (e, {value}) => {
    //     this.onMarkerClick(value, firebaseConstants.FIREBASE_EVENT_LOCATION_DROPDOWN);
    // };

    // handleSelect = address => {
    //     geocodeByAddress(address)
    //       .then(results => getLatLng(results[0]))
    //       .then(latLng => {
    //         this.setState({address});
    //         // this.setState({ mapCenter: latLng})
    //       })
    //       .catch(error => console.error('Error', error));
    // };

    render() {

        let deviceLocations = [];
        let language = this.props.lg;
        let text = this.props.lang;
        let mapCenter = {
            lat: 7.8731,
            lng: 80.7718,
            // lat: 6.927079,
            // lng: 79.861244,
        };

        if (this.state.locationList.length !== 0) {
            this.state.locationList.map((value, index) => {
                return deviceLocations.push({
                    text: language === "en" ? value.location : language === "si" ? value.location_sin : value.location_tamil,
                    value: value.id,
                    key: value.id
                })
            })
        }
        let latitude = "";
        let logi = "";
        if (this.state.locationList.length !== 0) {
            this.state.locationList.map((value, index) => {
                if (this.state.selected === value.id) {
                    return latitude = value.latitude,
                        logi = value.longitude
                }
            })
        }
        if(this.props.locationId)  {
            let find = this.state.locationList.find((obj) => obj.id === this.props.locationId);
            if (find) {
                mapCenter.lat = find.latitude
                mapCenter.lng = find.longitude
            }
        }
        return (
            <div className={"google-auto-complete"}>

                {/*<Dropdown*/}
                {/*placeholder={text.search}*/}
                {/*fluid*/}
                {/*selection*/}
                {/*search*/}
                {/*options={deviceLocations}*/}
                {/*// value={this.state.address}*/}
                {/*onChange={this.handleDropdownChange}*/}
                {/*// onSelect={this.handleSelect}*/}
                {/*/>*/}
                <Card className="mapCard">

                    <Map google={this.props.google}
                        zoom={7}
                        scrollwheel={true}
                        fullscreenControl={true}
                        initialCenter={{
                            lat: latitude,
                            lng: logi
                        }}
                        center={{
                            lat: mapCenter.lat,
                            lng: mapCenter.lng
                        }}
                        gestureHandling={'greedy'}
                         options={{
                             disableDefaultUI: true,
                             mapTypeControl: true,
                             streetViewControl: true,
                             // styles: MapProperties,
                             styles: [
                                 { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
                                 { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
                                 { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
                                 {
                                     featureType: "administrative.locality",
                                     elementType: "labels.text.fill",
                                     stylers: [{ color: "#d59563" }]
                                 },
                                 {
                                     featureType: "poi",
                                     elementType: "labels.text.fill",
                                     stylers: [{ color: "#d59563" }]
                                 },
                                 {
                                     featureType: "poi.park",
                                     elementType: "geometry",
                                     stylers: [{ color: "#263c3f" }]
                                 },
                                 {
                                     featureType: "poi.park",
                                     elementType: "labels.text.fill",
                                     stylers: [{ color: "#6b9a76" }]
                                 },
                                 {
                                     featureType: "road",
                                     elementType: "geometry",
                                     stylers: [{ color: "#38414e" }]
                                 },
                                 {
                                     featureType: "road",
                                     elementType: "geometry.stroke",
                                     stylers: [{ color: "#212a37" }]
                                 },
                                 {
                                     featureType: "road",
                                     elementType: "labels.text.fill",
                                     stylers: [{ color: "#9ca5b3" }]
                                 },
                                 {
                                     featureType: "road.highway",
                                     elementType: "geometry",
                                     stylers: [{ color: "#746855" }]
                                 },
                                 {
                                     featureType: "road.highway",
                                     elementType: "geometry.stroke",
                                     stylers: [{ color: "#1f2835" }]
                                 },
                                 {
                                     featureType: "road.highway",
                                     elementType: "labels.text.fill",
                                     stylers: [{ color: "#f3d19c" }]
                                 },
                                 {
                                     featureType: "transit",
                                     elementType: "geometry",
                                     stylers: [{ color: "#2f3948" }]
                                 },
                                 {
                                     featureType: "transit.station",
                                     elementType: "labels.text.fill",
                                     stylers: [{ color: "#d59563" }]
                                 },
                                 {
                                     featureType: "water",
                                     elementType: "geometry",
                                     stylers: [{ color: "#17263c" }]
                                 },
                                 {
                                     featureType: "water",
                                     elementType: "labels.text.fill",
                                     stylers: [{ color: "#515c6d" }]
                                 },
                                 {
                                     featureType: "water",
                                     elementType: "labels.text.stroke",
                                     stylers: [{ color: "#17263c" }]
                                 }
                             ]
                         }}

                         // options={MapProperties}
                    >
                        {
                            this.state.locationList.map(value => {
                                return <Marker
                                    key={value.id}
                                    // BOUNCE, DROP
                                    animation={this.props.google.maps.Animation.DROP}
                                    title={language === "en" ? value.location : language === "si" ? value.location_sin : value.location_tamil}
                                    name={language === "en" ? value.location : language === "si" ? value.location_sin : value.location_tamil}
                                    onClick={() => this.onMarkerClick(value.id, firebaseConstants.FIREBASE_EVENT_LOCATION_MAP)}
                                    position={{ lat: value.latitude, lng: value.longitude }}
                                    icon={{
                                        url: value.aqiLabel === 'Excellent' ? excellent : value.aqiLabel === 'Good' ? good :
                                            value.aqiLabel === 'Moderate' ? moderate : value.aqiLabel === 'Poor' ? poor :
                                                value.aqiLabel === 'Very Poor' ? veryPoor : value.aqiLabel === 'Severe' ? severe : map,
                                        fillColor: '#0000ff'
                                    }}
                                />
                            })
                        }
                        {/*{ <Marker*/}
                        {/*position={{*/}
                        {/*lat:this.state.mapCenter.lat,*/}
                        {/*lng:this.state.mapCenter.lng*/}
                        {/*}}*/}
                        {/*/> }*/}

                    </Map>
                </Card>

            </div>

        )
    }
}
// console.log("localStorage.getItem(\"lang\")",localStorage.getItem("lang"))
export default GoogleApiWrapper({
    apiKey: ("AIzaSyD9Juj4XYGRnE19y6p6-urYftTDEEafGkU"),
    language: localStorage.getItem("lang") ? localStorage.getItem("lang") : "en",
})(MapContainer)

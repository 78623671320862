import React,{Component} from 'react';
import {Label,Row,Col} from "reactstrap";
import './style.scss';
import { Line } from "react-chartjs-2";
import "chartjs-plugin-annotation";
import *  as dateGenerator from '../../../utility/DateGenerator';
import LineChart from '../line-chart/LineChart';
import ChartLegend from "../chart-legend/ChartLegend";


class AqiGraph extends Component{

    state={
        days:[]
    };

    componentDidMount(){
        this.getLast7Days();
    }


    getLast7Days () {
        let result = dateGenerator.getLast7Days();
        this.setState({
            days:result
        })
    }
    // function formatDate(date){
    //     var dd = date.getDate();
    //     var mm = date.getMonth()+1;
    //     var yyyy = date.getFullYear();
    //     if(dd<10) {dd='0'+dd}
    //     if(mm<10) {mm='0'+mm}
    //     date = mm+'/'+dd+'/'+yyyy;
    //     return date
    // }
    //
    //
    //
    // function Last7Days () {
    //     var result = [];
    //     for (var i=0; i<7; i++) {
    //         var d = new Date();
    //         d.setDate(d.getDate() - i);
    //         result.push( formatDate(d) )
    //     }
    //
    //     return(result.join(','));
    // }
    //
    // console.log(Last7Days())

    render() {
        let days = dateGenerator.getLast7Days();
        let finalizeData = [];
        let finalizeDates = [];
        // let
        let {lg,data}=this.props;
        if(data && data.days && data.days.length > 0) {
            let dates = data.days;
            let bodyData = data.data;
            days.map((obj,index)=>{
                let tempIndex = dates.findIndex(dateObj => dateObj === obj);
                finalizeDates.push(obj);
                finalizeData.push(tempIndex !== -1 ? bodyData[tempIndex] : 0);
                // data
            })
            // data.days.map((obj,index) => {
            //
            // })
        }

        return (
            <div className={'aqi-graph-card'}>
                <Label className={'main-lbl'} style={lg === 'ta' ? {fontSize:'18px'} :{fontSize:'22px'}}>Text</Label>
                <div className={'main-div d-block air-qlty-wrapper'}>
                    {/*<Line datasetIdKey='id' data={data} options={dateGenerator.options}/>*/}
                    {/*<LineChart data={this.props.data.data} days={this.props.data.days} type={"default"} yAxisName={this.props.yAxisName}/>*/}
                    <LineChart data={finalizeData} days={finalizeDates} type={"default"} yAxisName={this.props.yAxisName}/>
                    {/*<ChartLegend chartType={null} />*/}
                    <div>
                        <ChartLegend chartType={"*"} />
                    </div>
                </div>
            </div>
        );
    }
}

export default AqiGraph;

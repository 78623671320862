import React, { Component } from 'react';
// import Loading from "../../../components/Loading/Loading";
import { Row, Col, Spinner, Label } from 'reactstrap';
import Header from '../../../components/revamp/header/landingPg/Header';
import Precaution from '../../../components/revamp/precaution-card/PrecautionCard';
import Speedometer from '../../../components/revamp/speedometer/Speedometer';
import Aqi from '../../../components/revamp/aqi/AQI';
import AqiGraph from '../../../components/revamp/aqi-graph/AqiGraph';
import AqiMeasure from '../../../components/revamp/aqi-measures/landingPg/AqiMeasures';
import AqiMap from '../../../components/revamp/map/AqiMap';
import Loading from "../../../components/Loading/Loading";
import * as interfaceService from "../../../services/interface";
import './style.scss';
import * as firebaseConfigs from "../../../config/index";
import * as firebaseConstants from "../../../config";
import firebase_ from "firebase";
import { Modal, notification } from "antd";
import vaayu_logo from "../../../assets/logo/Vaayu Logo/Logo.png";
import Footer from '../../../components/revamp/footer/Footer';
import moment from "moment";
import * as config from '../../../config'
import ChartLegend from "../../../components/revamp/chart-legend/ChartLegend";

const alltexts = require('../../../components/lang/language.json');
let isSubscribed = false;
let messaging = null;

class LandingPage extends Component {


    state = {
        latitude: '',
        longitude: '',
        device_id: "",
        nearest_location: '',
        locations: [],
        aqi: '',
        aqiLabel: '',
        aqiColor: '',
        deviceData: [],
        visibleList: [],
        createdAt: '',
        param_info: [],
        label_clicked: true,
        city: "",
        userToken: "",
        loading: false,
        color: [],
        activity: [],
        date: new Date(),
        language: localStorage.getItem("lang") === null ? 'en' : localStorage.getItem("lang"),
        noneFile: '',
        loader: true,
        subscribed_list: [],
        location_id: 35,
        graph_data: { data: [], days: [] },

    };

    async componentWillMount() {
        let lang = localStorage.getItem("lang");
        if (lang) {
            await this.setState({
                language: lang
            });
        }
        this.getLocation();
        this.firebaseConfig(0);
    };

    toggleLoader = (condition) => {
        this.setState({ loader: condition });
    };

    //handle language
    handleLanguage = async (value) => {
        await this.setState({language: value});
        localStorage.setItem("lang", value);
        let language = value === "si" ? "sinhala" : value === 'en' ? "english" : "tamil";
        this.firebaseAnalyticsHandler(firebaseConstants.FIREBASE_EVENT_LANGUAGE, value.toString(), language, value.toString());
        // this.getLocation("LANG");
        this.toggleLoader(true);
        window.location.reload();
    };

    restApiFunction = async (lang, lat, long, status) => {
        this.toggleLoader(true);
        const location = {
            "lang": lang,
            "lat": lat,
            "long": long
        };
        await interfaceService.getLocation(location)
            .then(async response => {
                if (response.status !== 0) {
                    if (status !== "LANG") {
                        await this.setState({
                            locations: response.locations,
                            device_id: response.nearBy[0].id
                        })
                    } else {
                        await this.setState({
                            locations: response.locations
                        })
                    }
                    this.getData();
                    this.getChartData();
                } else {
                    this.toggleLoader(false);
                }
            })
            .catch(error => {
                this.toggleLoader(false);
                console.log(error)
            });
    };

    getLocation = (status) => {
        navigator.geolocation.getCurrentPosition((position) => {
            this.setState({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude
            });
            this.restApiFunction(this.state.language, position.coords.latitude, position.coords.longitude, status)
        },
            error => {
                console.log("getLocation_error: ", error);
                this.setState({
                    latitude: 6.722747,
                    longitude: 79.912228
                });
                this.restApiFunction(this.state.language, 6.722747, 79.912228)
            },
        );
    };

    getData = async () => {
        this.toggleLoader(true);
        await interfaceService.getDevice(this.state.language, this.state.device_id)
            .then(response => {
                // --- finalize single array ---
                let visibleList = [];

                let displayableParams = response["displayable _params"];
                let valuesObject = response["values"];
                let colorsObject = response["colors"];
                let statusTipObject = response["status_tip"];
                let nameMapArray = response["mappings"];
                let infoArray = response["param_info"];

                Object.keys(displayableParams).map((key, index) => {
                    if (displayableParams[key] === 1) {
                        let nameMapObj = nameMapArray.find((obj) => obj.parameter === key);
                        let infoObject = infoArray.find((obj) => obj.parameter === key);

                        visibleList.push({
                            index: index,
                            symbol: key,
                            name: nameMapObj ? this.state.language === "en" ? nameMapObj.parameter_name : this.state.language === "si" ? nameMapObj.parameter_name_sin : nameMapObj.parameter_name_tamil : "",
                            color: colorsObject[key],
                            value: valuesObject[key],
                            statusTip: statusTipObject[key],
                            info: infoObject ? this.state.language === "en" ? infoObject.info : this.state.language === "si" ? infoObject.info_sin : infoObject.info_tamil : ""
                        })
                    }
                });
                // -----------
                let aqiValue = parseInt(Number(response.aqi).toFixed(0));
                aqiValue = aqiValue > 500 ? 500 : aqiValue;

                this.setState({
                    deviceData: response,
                    aqi: aqiValue,
                    aqiLabel: response.aqiData.aqiLabel,
                    aqiColor: response.aqiData.aqiColor,
                    createdAt: response.values.created_at,
                    city: response.city,
                    activity: response.aqiData.aqiActivity,
                    date: new Date(),
                    visibleList
                });

                this.toggleLoader(false);

                if (visibleList.length === 0) {
                    this.toggleLoader(false);
                }
            })
            .catch(error => {
                console.log(error);
                this.toggleLoader(false);
            })
    };

    getIdOnLanding = async (value, firebaseEvent) => {
        let { locations, language } = this.state;
        await this.setState({
            device_id: value,
            location_id: value
        });
        let locationObj = locations.find(obj => obj.id === value);
        let item_name = language === "en" ? locationObj.location : language === "si" ? locationObj.location_sin : locationObj.location_tamil;


        this.firebaseAnalyticsHandler(firebaseEvent, `ID_${value}`, item_name, `ID_${value}`);
        // this.firebaseAnalyticsHandler(firebaseEvent, value.toString(), item_name, value.toString());
        this.getData(value);
        this.getChartData();
    };

    firebaseConfig = (type) => {
        try {
            let firebase = window.firebase || [];
            firebase_.initializeApp(firebaseConfig);
            console.log("firebase.messaging.isSupported()", firebase.messaging.isSupported())
            if (firebase.messaging.isSupported()) {
                // Initialize Firebase
                if (!firebase.apps.length) {
                    firebase.initializeApp(firebaseConfig);
                } else {
                    firebase.app(); // if already initialized, use that one
                }
                firebase.analytics();

                messaging = firebase.messaging();
                this.resetUI(messaging,type);
                this.requestPermission(type);
            }
        } catch (e) {
            console.log("firebaseConfig error!", e)
        }
    };

    resetUI = (messaging,type) => {
        // Add the public key generated from the console here.
        messaging.getToken({ vapidKey: firebaseConfigs.FIREBASE_KEY })
            .then(async (currentToken) => {
                if (currentToken) {
                    await this.setState({ userToken: currentToken });
                    if(type === 1) this.onClickButton(null);

                    this.sendTokenToServer(currentToken);
                    await interfaceService.checkSubscription({ token: currentToken })
                        .then(async response => {
                            if (response.success !== false) {
                                this.setState({ subscribed_list: typeof response === "object" ? response : [] })
                            }
                        })
                        .catch(error => {
                            this.setState({ subscribed_list: [] })
                        });
                    // this.updateUIForPushEnabled(currentToken);
                } else {
                    // Show permission request.
                    console.log('No registration token available. Request permission to generate one.');
                    // this.updateUIForPushPermissionRequired();
                    this.setTokenSentToServer(false);
                }
            })
            .catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
                this.setTokenSentToServer(false);
            });

        messaging.onMessage((payload) => {
            // alert(payload.notification.title+ "\n" +payload.notification.body)
            this.openNotification('topRight', payload);
        });
    };

    sendTokenToServer = (currentToken) => {
        if (!this.isTokenSentToServer()) {
            console.log('Sending token to server...');
            // TODO(developer): Send the current token to your server.
            this.setTokenSentToServer(true);
        } else {
            console.log('Token already sent to server so won\'t send it again ' +
                'unless it changes');
        }
    };

    isTokenSentToServer = () => {
        return window.localStorage.getItem('sentToServer') === '1';
    };

    setTokenSentToServer = (sent) => {
        window.localStorage.setItem('sentToServer', sent ? '1' : '0');
    };

    requestPermission = (type) => {
        messaging.getToken({ vapidKey: firebaseConfigs.FIREBASE_KEY })
            .then(async (currentToken) => {
                if (currentToken) {
                    messaging.requestPermission().then((permission) => {
                        this.resetUI(messaging,type);
                    });
                }else{
                    this.setState({ loading: false });
                }
            })
    };

    openNotification = (placement, payload) => {
        console.log('Message received. ', payload);
        notification.open({
            message: <b>{payload.notification.title}</b>,
            description: payload.notification.body,
            icon: <img src={vaayu_logo} alt="vl" style={{ width: "30px", height: "30px" }} />,
            placement
        });
    };

    firebaseAnalyticsHandler = (content_type, content_id, item_name, item_id) => {
        const analytics = firebase_.analytics();
        content_id = content_id ? content_id.toString() : "";
        item_id = item_id ? item_id.toString() : "";
        item_name = item_name ? item_name.toString() : "";

        console.log("firebaseAnalyticsHandler:content_type:", content_type);
        console.log("firebaseAnalyticsHandler:content_id:", content_id);
        console.log("firebaseAnalyticsHandler:item_name:", item_name);
        console.log("firebaseAnalyticsHandler:item_id:", item_id);

        analytics.logEvent('select_content', {
            content_type: content_type,
            content_id: content_id,
            items: [{ item_name: item_name, item_id: item_id }]
        });
    };

    onSubscriptionSuccess = (placement, isSubscribed) => {
        let languageTexts = alltexts[this.state.language];
        let message = !isSubscribed ? languageTexts.subscribe_success : languageTexts.unsubscribe_success;
        Modal.success({
            // content: `Successfully ${isSubscribed ? `Unsubscribe` : `Subscribe`} To Vaayu AQI alerts`,
            content: message,
            placement
        });
        let { device_id, subscribed_list, language } = this.state;
        if (subscribed_list.length !== 0 && isSubscribed) {
            let index = -1;
            subscribed_list.find((id, i) => {
                if (id === `${device_id}_${language}`) {
                    index = i;
                }
            });
            subscribed_list.splice(index, 1);
            this.setState({ subscribed_list })
        } else {
            subscribed_list.push(`${device_id}_${language}`)
        }
    };
    onSubscriptionFailed = (placement, isSubscribed) => {
        let languageTexts = alltexts[this.state.language];
        let message = languageTexts.request_fail;
        Modal.error({
            // content: `${isSubscribed ? `Subscription` : `Un-subscription`}  Failed, Please try again`,
            content: message,
            placement
        });
    };
    onClickButton = async (isSubscribed_) => {
        if(isSubscribed_ === null) isSubscribed_ = isSubscribed;
        let userToken = this.state.userToken;
        this.setState({ loading: true });
        if(userToken === "" || userToken === null) return this.requestPermission(1)
        let { locations, device_id, language } = this.state;
        let obj = {
            "isSubscribe": !isSubscribed_,
            "id": `${device_id}_${language}`,
            "token": this.state.userToken,
        };

        await interfaceService.sendDevice(obj)
            .then(response => {
                Object.keys(response[`${this.state.device_id}_${language}`]).map((key, index) => {
                    if (response[`${this.state.device_id}_${language}`][key] === "OK") {
                        let locationObj = locations.find(obj => obj.id === device_id);
                        let item_name = language === "en" ? locationObj.location : language === "si" ? locationObj.location_sin : locationObj.location_tamil;
                        if (isSubscribed_ === false) {
                            this.firebaseAnalyticsHandler(firebaseConstants.FIREBASE_EVENT_SUBSCRIBE, `ID_${device_id}_|_${language}`, item_name, `ID_${device_id}_|_${language}`);
                        }
                        this.onSubscriptionSuccess('topRight', isSubscribed_)
                    } else {
                        this.onSubscriptionFailed('topRight', isSubscribed_)
                    }
                });
                this.setState({ loading: false })
            })
            .catch(error => {
                console.log(error);
                this.setState({ loading: false })
                this.onSubscriptionFailed('topRight', isSubscribed_)
            })
    };

    getChartData = async () => {
        const tempData = {}
        const data = []
        const days = []
        await interfaceService.getNewChartData(this.state.device_id).then(response => {
            if (response.aqi_chart_data !== undefined && response.aqi_chart_data.length !== 0) {
                let temp = response.aqi_chart_data.reverse()
                if (temp.length <= 3) {
                    let arrayLength = temp.length
                    let num = 7 - arrayLength
                    for (let i = num; i > 0; i--) {
                        data.push(0.00)
                        days.push(moment(new Date(temp[arrayLength - 1].updated_at.substring(0, 10))).subtract(i, 'days').format('DD/MM/YYYY'))
                    }
                }
                temp.map(item => {
                    data.push(Number(item.aqi_value).toFixed(2))
                    days.push(moment(item.updated_at.substring(0, 10)).format("DD/MM/YYYY"))
                })
                tempData.data = data
                tempData.days = days
            }
            this.setState({ graph_data: tempData })
            window.scrollTo(0, 0)
        })
    }

    render() {
        let isSubscribed_ = false;
        let { device_id, subscribed_list, language, city } = this.state;
        let languageTexts = "";

        if (this.state.language === "si") {
            languageTexts = alltexts.si;
        } else if (this.state.language === "ta") {
            languageTexts = alltexts.ta;
        } else {
            languageTexts = alltexts.en;
        }
        if (subscribed_list.length !== 0 && device_id) {
            isSubscribed = subscribed_list.find(id => id === `${device_id}_${language}`);
            isSubscribed_ = isSubscribed;
        }

        const lan = localStorage.getItem("lang")
        return (
            <div className="new-landing">
                <Loading lang={languageTexts} isLoader={this.state.loader} />
                <div className={'background-img'}>
                    <Header location={this.state.locations} changeLanguage={this.handleLanguage} locationId={this.state.location_id}
                        lang={languageTexts} lg={language} changeLocation={this.getIdOnLanding} />
                </div>
                <div className={'content-div web-landing-version'}>
                    <div className={'body-div'}>
                        <Row>
                            <Col xs={12}>
                                <AqiMap locationsList={this.state.locations}
                                        getMarkerId={this.getIdOnLanding}
                                        lang={languageTexts} lg={language}
                                        locationId={this.state.location_id}
                                />
                            </Col>
                            <Col lg={4} md={12} xl={4} className={'col-speedometer'} style={{marginTop: "0px"}}>
                                <Speedometer aqiValue={this.state.aqi} lang={languageTexts} city={city} lg={language} deviceData={this.state.deviceData} />
                            </Col>
                            <Col xs={4} className={'map-wrapper-v1'}>
                                {/*<AqiMap locationsList={this.state.locations}*/}
                                {/*        getMarkerId={this.getIdOnLanding}*/}
                                {/*        lang={languageTexts} lg={language}*/}
                                {/*        locationId={this.state.location_id}*/}
                                {/*/>*/}
                            </Col>
                            <Col lg={4} md={12} xl={4} className={'col-precaution'} style={{marginTop: "0px"}}>
                                <Precaution activityList={this.state.activity} lang={languageTexts} lg={language} />
                            </Col>
                        </Row>
                        <br />

                        {/* waiting for the right wording from client (Hide content) */}

                        {/* <p className={'p-div'}>{languageTexts.description}</p> */}

                        <br />
                        <div className={'subscribe-div'}>
                            <Row className={'row-div'}>
                                <Col lg={7} className={'lbl'}>{languageTexts.subscribe_text}</Col>
                                <Col lg={5} className={'btn-right'}>
                                    <div className={'btn'} onClick={() => this.onClickButton(isSubscribed_)}>
                                        {this.state.loading ? <div style={{ padding: '2px 60px' }}><Spinner size="sm" color="white" /></div> :
                                            <Label style={{ marginBottom: '2px', cursor: 'pointer' }}>{isSubscribed_ ? languageTexts.un_subscribe : languageTexts.subscribe}</Label>}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <br />
                        <Row>
                            <Col lg={6}>
                                <Aqi lang={languageTexts} lg={language} />
                            </Col>
                            <Col lg={6}>
                                <AqiGraph lg={language} data={this.state.graph_data} yAxisName={lan === config.SINHALA ? "වාතයේ ගුණාත්මකභාවය (US AQI)" : lan === config.TAMIL ? "காற்றின் தரம் (US AQI)" : "Air Quality (US AQI)"} />

                            </Col>
                        </Row>
                        {/*<br />*/}
                        <Row>
                            <Col xs={12} className={'measure-col'}>
                                <AqiMeasure data={this.state.visibleList} lang={languageTexts} deviceId={device_id} lg={language} />
                            </Col>
                            {/*<Col lg={8}>*/}
                            {/*    <AqiMap locationsList={this.state.locations}*/}
                            {/*        getMarkerId={this.getIdOnLanding}*/}
                            {/*        lang={languageTexts} lg={language}*/}
                            {/*        locationId={this.state.location_id}*/}
                            {/*    />*/}
                            {/*</Col>*/}
                        </Row>
                    </div>
                    <Footer lang={languageTexts} />
                </div>
            </div>
        );
    }
}

const firebaseConfig = {
    apiKey: firebaseConfigs.FIREBASE_API_KEY,
    authDomain: firebaseConfigs.FIREBASE_AUTH_DOMAIN,
    projectId: firebaseConfigs.FIREBASE_PROJECT_ID,
    storageBucket: firebaseConfigs.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: firebaseConfigs.FIREBASE_SENDER_ID,
    appId: firebaseConfigs.FIREBASE_APP_ID,
    measurementId: firebaseConfigs.FIREBASE_MEASUREMENT_ID
};

export default LandingPage;

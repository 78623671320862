import React,{Component} from 'react';
import {Label,Row,Col} from "reactstrap";
import window from '../../../assets/icon/windows-no-red.png';
import cycling from '../../../assets/icon/cycling-no-red.png';
import mask from '../../../assets/icon/mask.png';
import air from '../../../assets/icon/air-purifier-no-green.png';

import './style.scss';

class AQI extends Component{


    render() {

        let {lg}=this.props;

        return (
            <div className={'aqi-card'}>
                <Label className={'main-lbl'} >{this.props.lang.aqi_heading}</Label>
                <div className={'main-div'}>
                    <p>{this.props.lang.aqi_info_para1}</p>
                    <p>{this.props.lang.aqi_info_para2}</p>
                </div>
            </div>
        );
    }
}

export default AQI;
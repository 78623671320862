import React,{Component} from 'react';
import ReactSpeedometer from "react-d3-speedometer";


import './style.scss';


class ChartTam extends Component{

    render() {
        return (
            <ReactSpeedometer
                maxValue={500}
                value={this.props.testValue}
                currentValueText={' '}
                width={325}
                height={184}
                segmentColors={["#00E400","#ffff00","#FF7E00","#FF0000","#8f3f97","#7e0023"]}
                // customSegmentStops={[0, 50, 100, 200, 300, 400, 500]}
                customSegmentStops={[0, 50, 100, 150, 200, 300, 500]}
                customSegmentLabels={[
                    // {text: 'மிகவும் நன்று',position: "OUTSIDE",color: "#555",fontSize:"8px"},
                    // {text: 'நன்று',position: "OUTSIDE",color: "#555",fontSize:"8px"},
                    // {text: 'நடுநிலை',position: "OUTSIDE",color: "#555",fontSize:"8px"},
                    // {text: 'மோசம்',position: "OUTSIDE",color: "#555",fontSize:"8px"},
                    // {text: 'மிக மோசம்',position: "OUTSIDE",color: "#555",fontSize:"8px"},
                    // {text: 'அதீத மோச நிலை',position: "OUTSIDE",color: "#555",fontSize:"8px"}

                    {text: '',position: "OUTSIDE",color: "#555",fontSize:"8px"},
                    {text: '',position: "OUTSIDE",color: "#555",fontSize:"8px"},
                    {text: '',position: "OUTSIDE",color: "#555",fontSize:"8px"},
                    {text: '',position: "OUTSIDE",color: "#555",fontSize:"8px"},
                    {text: '',position: "OUTSIDE",color: "#555",fontSize:"8px"},
                    {text: '',position: "OUTSIDE",color: "#555",fontSize:"8px"}
                ]}
            />
        );
    }
}

export default ChartTam;

import React, { Component } from 'react';
import { Label } from "reactstrap";
import MeterChartEng from './ChartEng';
import MeterChartSin from './ChartSin';
import MeterChartTam from './ChartTam';
import * as config from '../../../config'

import './style.scss';

const alltexts = require('../../../components/lang/language.json');

class Speedometer extends Component {

    state = {
        heading: 'Colombo, Sri Lanka',
    };


    render() {

        const lan = localStorage.getItem("lang")
        let { lang, lg } = this.props;
        let type = '';
        let englishLabel = [
            { text: 'Excellent', position: "OUTSIDE", color: "#555", fontSize: "13px" },
            { text: 'Good', position: "OUTSIDE", color: "#555", fontSize: "13px" },
            { text: 'Moderate', position: "OUTSIDE", color: "#555", fontSize: "13px" },
            { text: 'Poor', position: "OUTSIDE", color: "#555", fontSize: "13px" },
            { text: 'Very Poor', position: "OUTSIDE", color: "#555", fontSize: "13px" },
            { text: 'Severe', position: "OUTSIDE", color: "#555", fontSize: "13px" }
        ]
        let sinhalaLabel = [
            { text: 'ඉතා හොඳයි', position: "OUTSIDE", color: "#555", fontSize: "13px" },
            { text: 'හොඳයි', position: "OUTSIDE", color: "#555", fontSize: "13px" },
            { text: 'මධ්‍යස්ථ', position: "OUTSIDE", color: "#555", fontSize: "13px" },
            { text: 'දුර්වල', position: "OUTSIDE", color: "#555", fontSize: "13px" },
            { text: 'ඉතා දුර්වල', position: "OUTSIDE", color: "#555", fontSize: "13px" },
            { text: 'දරුණුයි', position: "OUTSIDE", color: "#555", fontSize: "13px" }
        ]
        let tamilLabel = [
            { text: 'சிறந்த', position: "OUTSIDE", color: "#555", fontSize: "13px" },
            { text: 'நல்ல', position: "OUTSIDE", color: "#555", fontSize: "13px" },
            { text: 'மிதமான', position: "OUTSIDE", color: "#555", fontSize: "13px" },
            { text: 'ஏழை', position: "OUTSIDE", color: "#555", fontSize: "13px" },
            { text: 'மிகவும் ஏழை', position: "OUTSIDE", color: "#555", fontSize: "13px" },
            { text: 'கடுமையான', position: "OUTSIDE", color: "#555", fontSize: "13px" }
        ]
        let temp = this.props.aqiValue;
        let labelName = '';
        // if (temp !== '') {
        //     type = (0 <= temp && temp < 50) ? "(" + this.props.lang.excellent + ")" :
        //         (50 <= temp && temp < 100) ? "(" + this.props.lang.good + ")" :
        //             (100 <= temp && temp < 200) ? "(" + this.props.lang.moderate + ")" :
        //                 (200 <= temp && temp < 300) ? "(" + this.props.lang.poor + ")" :
        //                     (300 <= temp && temp < 400) ? "(" + this.props.lang.very_poor + ")" :
        //                         (400 <= temp && temp < 500) ? "(" + this.props.lang.severe + ")" : ""
        // }
        // if (temp !== '') {
        //     type = (0 <= temp && temp <= 50) ? "(" + this.props.lang.excellent + ")" :
        //         (50 < temp && temp <= 100) ? "(" + this.props.lang.good + ")" :
        //             (100 < temp && temp <= 150) ? "(" + this.props.lang.moderate + ")" :
        //                 (150 < temp && temp <= 200) ? "(" + this.props.lang.poor + ")" :
        //                     (200 < temp && temp <= 300) ? "(" + this.props.lang.very_poor + ")" :
        //                         (300 < temp && temp <= 500) ? "(" + this.props.lang.severe + ")" : ""
        // }
        if (temp !== '') {
            type = (0 <= temp && temp <= 50) ? this.props.lang.excellent_long :
                (50 < temp && temp <= 100) ? this.props.lang.good_long :
                    (100 < temp && temp <= 150) ? this.props.lang.moderate_long :
                        (150 < temp && temp <= 200) ? this.props.lang.poor_long :
                            (200 < temp && temp <= 300) ? this.props.lang.very_poor_long :
                                (300 < temp && temp <= 500) ? this.props.lang.severe_long : ""

            labelName = (0 <= temp && temp <= 50) ? 'excellent' :
                (50 < temp && temp <= 100) ? 'good' :
                    (100 < temp && temp <= 150) ? 'moderate' :
                        (150 < temp && temp <= 200) ? 'poor' :
                            (200 < temp && temp <= 300) ? 'very_poor' :
                                (300 < temp && temp <= 500) ? 'severe' : ""
        }
        return (
            <div className={'speedometer-card'}>
                <Label className={'main-lbl'}>{this.props.lang.speedometer_heading}</Label>
                <div className={'main-div'}>
                    <div className={`clr-type-hd`}><p className={`${labelName}-label`}>{type}</p></div>
                    <div className={'sub-heading'}>{this.props.city + " " + this.props.lang.sl}</div>
                    {/*<br />*/}
                    <div className={'speedometer-div'}>
                        {lg === 'en' ? <MeterChartEng testValue={temp} /> :
                            lg === 'si' ? <MeterChartSin testValue={temp} /> :
                                <MeterChartTam testValue={temp} />
                        }
                        {/*<ReactSpeedometer*/}
                        {/*maxValue={500}*/}
                        {/*value={temp}*/}
                        {/*currentValueText={' '}*/}
                        {/*width={325}*/}
                        {/*height={184}*/}
                        {/*segmentColors={["#00E400","#FEFE00","#FF7E00","#FF0000","#98004B","#7D0022"]}*/}
                        {/*customSegmentStops={[0, 50, 100, 200, 300, 400, 500]}*/}
                        {/*customSegmentLabels={lg === 'en' ? englishLabel : lg === 'si' ? sinhalaLabel :tamilLabel  }*/}
                        {/*/>*/}
                        <div className={'label-div'}><Label>{temp}
                            <span className={'span-unit'}> US AQI </span>
                            {/*<span className={'span-type'}>{type}</span>*/}
                        </Label></div>

                        <div className={'label-div pb-3'}><Label>
                            <span className={'span-unit'}>{lan === config.SINHALA ? "යාවත්කාලීන වූ වෙලාව" : lan === config.TAMIL ? "இறுதியாக உறுதிப்படுத்தப்பட்டது" : "Last Updated"} </span>
                            <span className={'span-unit'}>{this.props.deviceData && this.props.deviceData.data ? this.props.deviceData.data.updated_at : ""}</span>
                        </Label></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Speedometer;

import React,{Component} from 'react';
import image from "../../assets/logo/Vaayu Logo/Logo.png";
import {Label} from "reactstrap";
// const alltexts = require('../../components/lang/language.json');

class App extends Component{
    render() {
        let lang = this.props.lang;

        return this.props.isLoader ?
            <div className={"loader-vaayu-wrapper header"}>
                <img src={image} alt="." style={{marginBottom: '15px'}} />
                <div><Label className="title"  style={{marginBottom: '15px'}}>{lang.breathe}</Label></div>
                <div className="loader-vaayu" />
            </div> : null
    }
}
export default App;

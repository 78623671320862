/**
 * Created by WebStorm.
 * User: athukorala
 * Date: 12/8/20
 * Time: 4:22 PM
 */

export let FIREBASE_API_KEY = "AIzaSyCiIML5jNz4HTFFwV6BgjDCD2G5-RSR4RY";
export let FIREBASE_AUTH_DOMAIN = "vaayu-cec79.firebaseapp.com";
export let FIREBASE_PROJECT_ID = "vaayu-cec79";
export let FIREBASE_STORAGE_BUCKET = "vaayu-cec79.appspot.com";
export let FIREBASE_SENDER_ID = "182242860834";
export let FIREBASE_APP_ID = "1:182242860834:web:805d0da169371dc07b8f7b";
export let FIREBASE_MEASUREMENT_ID = "G-FD3K9CGLJH";
export let FIREBASE_KEY = "BFAphPSOD82jw9oc3wtLGVumEUhyuMQJfEqbC2tigZhtxi363JwWLVcKMnGhjgj6f1U5EU8rsre5R-xTZbwP-MU";

// firebase select_content events

export let FIREBASE_EVENT_LANGUAGE = "language";
export let FIREBASE_EVENT_LOCATION_MAP = "location_map_click";
export let FIREBASE_EVENT_LOCATION_DROPDOWN = "location_dropdown";
export let FIREBASE_EVENT_LOCATION_TEST = "location_test";
export let FIREBASE_EVENT_SUBSCRIBE = "subscriptions";
export let FIREBASE_EVENT_TILE_CLICK = "tile_click";
export let FIREBASE_EVENT_VIEW_MORE = "view_more_details";
export let FIREBASE_EVENT_DEVICE = "device";
export let FIREBASE_EVENT_MOBILE_TAB = "mobile_tab";

// languages
export const ENGLISH = "en"
export const SINHALA = "si"
export const TAMIL = "ta"


// chart

// chart type

export const TEMPERATURE_TYPE = "a";
export const PM2_TYPE = "r";
export const PM10_TYPE = "s";

// chart y axis label
export const PM2_YAXIS = "a";
export const PM10_YAXIS = "r";
export const TEMPERATURE_YAXIS = "s";

// chart annotation
export const ANNOTATION_TEMPERATURE = [
    {
        type: "box",
        yScaleID: 'y-axis-0',
        yMin: 35.1,
        yMax: 39.9,
        borderColor: "rgb(153,52,0)",
        borderWidth: 0,
        drawTime: 'beforeDatasetsDraw',
        backgroundColor: "rgb(153,52,0)",
        // lang_name:"very_hot",
    },
    {
        type: "box",
        yScaleID: 'y-axis-0',
        yMin: 30.0,
        yMax: 35.0,
        borderColor: "rgb(235,79,79)",
        borderWidth: 0,
        drawTime: 'beforeDatasetsDraw',
        backgroundColor: "rgb(235,79,79)",
        display: true,
        // lang_name: "hot"
    },
    {
        type: "box",
        yScaleID: 'y-axis-0',
        yMin: 26.0,
        yMax: 29.9,
        borderColor: "rgb(239,138,69)",
        borderWidth: 0,
        drawTime: 'beforeDatasetsDraw',
        backgroundColor: "rgb(239,138,69)",
        // lang_name: "very_warm_hot"
    },
    {
        type: "box",
        yScaleID: 'y-axis-0',
        yMin: 22.6,
        yMax: 25.9,
        borderColor: "rgb(255,221,117)",
        borderWidth: 0,
        drawTime: 'beforeDatasetsDraw',
        backgroundColor: "rgb(255,221,117)",
        // lang_name: "warm"
    },
    {
        type: "box",
        yScaleID: 'y-axis-0',
        yMin: 20.1,
        yMax: 22.5,
        borderColor: "rgb(255,253,153)",
        borderWidth: 0,
        drawTime: 'beforeDatasetsDraw',
        backgroundColor: "rgb(255,253,153)",
        display: true,
        // lang_name: "warm_mild"
    },
    {
        type: "box",
        yScaleID: 'y-axis-0',
        yMin: 17,
        yMax: 20,
        borderColor: "rgb(148,200,93)",
        borderWidth: 0,
        drawTime: 'beforeDatasetsDraw',
        backgroundColor: "rgb(148,200,93)",
        // lang_name: "mild"
    },
    {
        type: "box",
        yScaleID: 'y-axis-0',
        yMin: 15,
        yMax: 16.9,
        borderColor: "rgb(210,251,208)",
        borderWidth: 0,
        drawTime: 'beforeDatasetsDraw',
        backgroundColor: "rgb(210,251,208)",
        display: false,
        // lang_name: "cool_mild"
    },
    {
        type: "box",
        yScaleID: 'y-axis-0',
        yMin: 14,
        yMax: 14.9,
        borderColor: "rgb(204,255,255)",
        borderWidth: 0,
        drawTime: 'beforeDatasetsDraw',
        backgroundColor: "rgb(204,255,255)",
        // lang_name: "cool"
    },
    {
        type: "box",
        yScaleID: 'y-axis-0',
        yMin: 12,
        yMax: 13.9,
        borderColor: "rgb(153,205,255)",
        borderWidth: 0,
        drawTime: 'beforeDatasetsDraw',
        backgroundColor: "rgb(153,205,255)",
        display: true,
        // lang_name: "getting_cold"
    },
    {
        type: "box",
        yScaleID: 'y-axis-0',
        yMin: 10,
        yMax: 11.9,
        borderColor: "rgb(138,180,248)",
        borderWidth: 0,
        drawTime: 'beforeDatasetsDraw',
        backgroundColor: "rgb(138,180,248)",
        // lang_name: "cold"
    },
    {
        type: "box",
        yScaleID: 'y-axis-0',
        yMin: 5,
        yMax: 9.9,
        borderColor: "rgb(50,102,255)",
        borderWidth: 0,
        drawTime: 'beforeDatasetsDraw',
        backgroundColor: "rgb(50,102,255,0.9)",
        display: true,
        // lang_name: "very_cold"
    }
]
export const ANNOTATION_PM2 = [

    // comment this object because chart is too height (set max height to 350)

    // {
    //     type: "box",
    //     yScaleID: 'y-axis-0',
    //     yMin: 350.5,
    //     yMax: 550, // 500.4 change to 550
    //     borderColor: "rgb(125,0,34)",
    //     borderWidth: 0,
    //     drawTime: 'beforeDatasetsDraw',
    //     backgroundColor: "rgb(125,0,34)",
    //     height: '5%',
    // },
    {
        type: "box",
        yScaleID: 'y-axis-0',
        yMin: 250.5,
        yMax: 300, // change 350.4 to 300
        borderColor: "rgb(125,0,34)",
        borderWidth: 0,
        drawTime: 'beforeDatasetsDraw',
        backgroundColor: "rgb(125,0,34)",
        display: true,
        // lang_name:"hazardous_unit",
        lang_name:"severe_long",
        background: "#7e0023"
    },
    {
        type: "box",
        yScaleID: 'y-axis-0',
        yMin: 150.5,
        yMax: 250.4,
        borderColor: "rgb(152,0,75)",
        borderWidth: 0,
        drawTime: 'beforeDatasetsDraw',
        backgroundColor: "rgb(152,0,75)",
        // lang_name:"very_unhealthy_unit",
        lang_name:"very_poor_long",
        background: "#8f3f97"
    },
    {
        type: "box",
        yScaleID: 'y-axis-0',
        yMin: 55.6,
        yMax: 150.4,
        borderColor: "rgb(235,79,79)",
        borderWidth: 0,
        drawTime: 'beforeDatasetsDraw',
        backgroundColor: "rgb(235,79,79)",
        // lang_name:"unhealthy_unit",
        lang_name:"poor_long",
        background: "#FF0000"
    },
    {
        type: "box",
        yScaleID: 'y-axis-0',
        yMin: 35.5,
        yMax: 55.5,
        borderColor: "rgb(239,138,69)",
        borderWidth: 0,
        drawTime: 'beforeDatasetsDraw',
        backgroundColor: "#FF7E00",
        display: true,
        // lang_name:"unhealthy_normal_unit",
        lang_name:"moderate_long",
        background: "#FF7E00"
    },
    {
        type: "box",
        yScaleID: 'y-axis-0',
        yMin: 12.1,
        yMax: 35.4,
        borderColor: "rgb(255,221,117)",
        borderWidth: 0,
        drawTime: 'beforeDatasetsDraw',
        backgroundColor: "#ffff00",
        // lang_name:"moderate_unit",
        lang_name:"good_long",
        background: "#ffff00"
    },
    {
        type: "box",
        yScaleID: 'y-axis-0',
        yMin: 0.0,
        yMax: 12.0,
        borderColor: "rgb(148,200,93)",
        borderWidth: 0,
        drawTime: 'beforeDatasetsDraw',
        backgroundColor: "#00E400",
        display: true,
        // lang_name:"good_unit",
        lang_name:"excellent_long",
        background: "#00E400"
    },
]
export const ANNOTATION_PM10 = [
    // {
    //     type: "box",
    //     yScaleID: 'y-axis-0',
    //     yMin: 505,
    //     yMax: 650, // 604.5 change to 650
    //     borderColor: "rgb(125,0,34)",
    //     borderWidth: 0,
    //     drawTime: 'beforeDatasetsDraw',
    //     backgroundColor: "rgb(125,0,34)",
    // },
    {
        type: "box",
        yScaleID: 'y-axis-0',
        yMin: 425,
        yMax: 500,
        borderColor: "rgb(125,0,34)",
        borderWidth: 0,
        drawTime: 'beforeDatasetsDraw',
        backgroundColor: "#7e0023",
        display: true,
        // lang_name:"hazardous_unit",
        lang_name:"severe_long",
        background: "#7e0023"
    },
    {
        type: "box",
        yScaleID: 'y-axis-0',
        yMin: 355,
        yMax: 424,
        borderColor: "rgb(152,0,75)",
        borderWidth: 0,
        drawTime: 'beforeDatasetsDraw',
        backgroundColor: "#8f3f97",
        // lang_name: "very_unhealthy_unit"
        lang_name:"very_poor_long",
        background: "#8f3f97"
    },
    {
        type: "box",
        yScaleID: 'y-axis-0',
        yMin: 255,
        yMax: 354,
        borderColor: "rgb(235,79,79)",
        borderWidth: 0,
        drawTime: 'beforeDatasetsDraw',
        backgroundColor: "#FF0000",
        // lang_name: "unhealthy_unit"
        lang_name:"poor_long",
        background: "#FF0000"
    },
    {
        type: "box",
        yScaleID: 'y-axis-0',
        yMin: 155,
        yMax: 254,
        borderColor: "rgb(239,138,69)",
        borderWidth: 0,
        drawTime: 'beforeDatasetsDraw',
        backgroundColor: "#FF7E00",
        display: true,
        // lang_name: "unhealthy_normal_unit"
        lang_name:"moderate_long",
        background: "#FF7E00"
    },
    {
        type: "box",
        yScaleID: 'y-axis-0',
        yMin: 55,
        yMax: 154,
        borderColor: "rgb(255,221,117)",
        borderWidth: 0,
        drawTime: 'beforeDatasetsDraw',
        backgroundColor: "#ffff00",
        // lang_name: "moderate_unit"
        lang_name:"good_long",
        background: "#ffff00"
    },
    {
        type: "box",
        yScaleID: 'y-axis-0',
        yMin: 0,
        yMax: 54,
        borderColor: "rgb(148,200,93)",
        borderWidth: 0,
        drawTime: 'beforeDatasetsDraw',
        backgroundColor: "#00E400",
        display: true,
        // lang_name: "good_unit"
        lang_name:"excellent_long",
        background: "#00E400"
    },
]

export const DEFAULT_ANNOTATION = [
    {
        type: "box",
        yScaleID: 'y-axis-0',
        yMin: 300,
        yMax: 500,
        borderColor: "rgba(125,0,34,0.9)",
        borderWidth: 0,
        drawTime: 'beforeDatasetsDraw',
        backgroundColor: "#7e0023",
        lang_name:"severe_long",
        background: "#7e0023"
    },
    {
        type: "box",
        yScaleID: 'y-axis-0',
        yMin: 200,
        yMax: 300,
        borderColor: "rgba(152,0,75,0.9)",
        borderWidth: 0,
        drawTime: 'beforeDatasetsDraw',
        backgroundColor: "#8f3f97",
        lang_name:"very_poor_long",
        background: "#8f3f97"
    },
    {
        type: "box",
        yScaleID: 'y-axis-0',
        yMin: 150,
        yMax: 200,
        borderColor: "rgb(233, 60, 60, 0.9)",
        borderWidth: 0,
        drawTime: 'beforeDatasetsDraw',
        backgroundColor: "#FF0000",
        lang_name:"poor_long",
        background: "#FF0000"
    },
    {
        type: "box",
        yScaleID: 'y-axis-0',
        yMin: 100,
        yMax: 150,
        borderColor: "rgb(237, 125, 49, 0.9)",
        borderWidth: 0,
        drawTime: 'beforeDatasetsDraw',
        backgroundColor: "#FF7E00",
        display: true,
        lang_name:"moderate_long",
        background: "#FF7E00"
    },
    {
        type: "box",
        yScaleID: 'y-axis-0',
        yMin: 50,
        yMax: 100,
        borderColor: "rgb(255, 217, 102, 0.9)",
        borderWidth: 0,
        drawTime: 'beforeDatasetsDraw',
        backgroundColor: "#ffff00",
        lang_name:"good_long",
        background: "#ffff00"
    },
    {
        type: "box",
        yScaleID: 'y-axis-0',
        yMin: 0,
        yMax: 50,
        borderColor: "rgb(136, 194, 75,0.9)",
        borderWidth: 0,
        drawTime: 'beforeDatasetsDraw',
        backgroundColor: "#00E400",
        display: false,
        lang_name:"excellent_long",
        background: "#00E400"
    }
]
export const DEFAULT_ANNOTATION_ = [
    {
        type: "box",
        yScaleID: 'y-axis-0',
        yMin: 300,
        yMax: 500,
        borderColor: "#7e0023",
        borderWidth: 0,
        drawTime: 'beforeDatasetsDraw',
        // backgroundColor: "rgba(125,0,34,0.9)",
        backgroundColor: "#7e0023",
        lang_name:"severe_long",
        background: "#7e0023"
    },
    {
        type: "box",
        yScaleID: 'y-axis-0',
        yMin: 200,
        yMax: 300,
        borderColor: "#8f3f97",
        borderWidth: 0,
        drawTime: 'beforeDatasetsDraw',
        // backgroundColor: "rgba(152,0,75,0.9)",
        backgroundColor: "#8f3f97",
        lang_name:"very_poor_long",
        background: "#8f3f97"
    },
    {
        type: "box",
        yScaleID: 'y-axis-0',
        yMin: 150,
        yMax: 200,
        borderColor: "#e93c3c",
        borderWidth: 0,
        drawTime: 'beforeDatasetsDraw',
        // backgroundColor: "rgb(233, 60, 60, 0.9)",
        backgroundColor: "#FF0000",
        lang_name:"poor_long",
        background: "#FF0000"
    },
    {
        type: "box",
        yScaleID: 'y-axis-0',
        yMin: 100,
        yMax: 150,
        borderColor: "#ed7d31",
        borderWidth: 0,
        drawTime: 'beforeDatasetsDraw',
        // backgroundColor: "rgb(237, 125, 49, 0.9)",
        backgroundColor: "#FF7E00",
        display: true,
        lang_name:"moderate_long",
        background: "#FF7E00"
    },
    {
        type: "box",
        yScaleID: 'y-axis-0',
        yMin: 50,
        yMax: 100,
        borderColor: "#ffd966",
        borderWidth: 0,
        drawTime: 'beforeDatasetsDraw',
        // backgroundColor: "rgb(255, 217, 102, 0.9)",
        backgroundColor: "#ffff00",
        lang_name:"good_long",
        background: "#ffff00"
    },
    {
        type: "box",
        yScaleID: 'y-axis-0',
        yMin: 0,
        yMax: 50,
        borderColor: "#88c24b",
        borderWidth: 0,
        drawTime: 'beforeDatasetsDraw',
        // backgroundColor: "rgb(136, 194, 75,0.9)",
        backgroundColor: "#00E400",
        display: false,
        lang_name:"excellent_long",
        background: "#00E400"
    }
]

import React,{Component} from 'react';
import {Label} from "reactstrap";
import wind from "../../../../assets/icon/wind.png";

import './style.scss'
import MoreDetail from "../../more-detail-modal/MoreDetail";

class AqiMeasures extends Component{

    state={
        modal:false,
        modal_data:{},
    };


    handleMoreDetail=(data)=>{
        this.setState({
            modal:!this.state.modal,
            modal_data:data,
        })
    };


    render() {

        let {lg}=this.props;
        let dataSet=[];

        if (this.props.data !== undefined && this.props.data.length !== 0){
            dataSet=this.props.data;
        }

        return (
            <div className={'mobile-measure-card'}>
                <Label className={'main-lbl'}>{this.props.lang.measure_heading}</Label>
                <div className={'main-div'}>
                    {
                        dataSet.map(item=>{
                            return <div className={'card-div'}>
                                <div className={'measure-card'}>
                                    <div className={'value-div'}>{item.value}</div>
                                    {item.name === 'Temperature' || item.name === 'උෂ්ණත්වය' || item.name === 'வெப்பநிலை' ? <p>°C</p> : <p>μg/m3</p>}
                                    <img src={wind} alt={''} width={'45px'}/>
                                    <div className={'type-div'}>{item.name}</div>
                                </div>
                                <div className={'view-lbl'} align="center" onClick={()=>this.handleMoreDetail(item)}>{this.props.lang.viewmore}</div>
                            </div>
                        })
                    }
                </div>
                {
                    this.state.modal &&
                    <MoreDetail close={()=>this.handleMoreDetail()}
                                visibility={this.state.modal}
                                detail={this.state.modal_data}
                                deviceId={this.props.deviceId}
                                lang={this.props.lang}
                    />
                }
            </div>
        );
    }
}

export default AqiMeasures;
import axios from 'axios';
import apiConfig from './apiConfig';
// import Cookies from "js-cookie";
// import {history} from "../history";
// import * as constant from "../configs/constant";
// import * as commonFunc from "../utility/commonFunc";

async function callApi(apiObject) {
  let body = {};
  let headers;
  let method = apiObject.method ? apiObject.method.toLowerCase() : 'get';
  if (method === 'post' || method === 'put') {
    if(!apiObject.multipart){
      body = apiObject.body ? JSON.stringify(apiObject.body) : {};
    }else{
      body = apiObject.body ? apiObject.body : {};
    }
  }

  headers = {
    'Content-Type': apiObject.multipart ? 'multipart/form-data':'application/json',
  };
  // if (apiObject.authentication) {
  //   headers.Authorization = JSON.parse(Cookies.get("ade451b857f0651566ad8aee75056ad9")).jwtAccessToken;
  // }

  let serverUrl = apiConfig.serverUrl;
  let basePath = apiConfig.basePath;

  // if (apiObject.basePath){
  //   basePath = apiObject.basePath;
  // }

  const url = `${serverUrl}/${basePath}/${apiObject.endpoint}`;
  let result;

  await axios[method](url, method !== 'get' ? body:{headers: headers} , {headers: headers})
    .then(async response => {
      result = await response.data;
    })
    .catch(async error => {
      if (error !== undefined) {
        if (error.response === undefined) {
          result = await {
            success: false,
            status: 2,
            message: "Your connection was interrupted",
            data: null,
          }
        } else if (error.response.status === 401) {
          result = await {
            success: false,
            status: 2,
            message: "Unauthorized",
            data: null,
          }

        } else if (error.response.status === 403) {
          result = await  {
            success: false,
            status: 2,
            message: "Access is denied.",
            data: null,
          };
        } else if (error.response.status === 417) {
          result = await  {
            success: false,
            status: 2,
            message: "Oops! Something went wrong.",
            data: null,
          };
        } else if(error.response.data !== undefined){
          result = await {
            success: false,
            status: 0,
            message: error.response.data.message,
            data: null,
          }
        } else {
          result = await  {
            success: false,
            status: 2,
            message: "Sorry, something went wrong.",
            data: null,
          };
        }
      } else {
        result = await  {
          success: false,
          status: 2,
          message: "Your connection was interrupted!",
          data: null,
        };
      }
    });

  return result;
}

export default callApi;

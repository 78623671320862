import React, { Component } from 'react';
import { Label, Row, Col } from "reactstrap";
import wind from '../../../../assets/icon/wind.png';
import MoreDetail from '../../more-detail-modal/MoreDetail';
import * as config from '../../../../config'

import './style.scss'

class AqiMeasures extends Component {

    state = {
        modal: false,
        modal_data: {},
    };


    handleMoreDetail = (data) => {
        this.setState({
            modal: !this.state.modal,
            modal_data: data
        })
    };


    render() {

        const lan = localStorage.getItem("lang")

        let { lg } = this.props;
        let dataSet = [];

        if (this.props.data !== undefined && this.props.data.length !== 0) {
            dataSet = this.props.data;
        }

        return (
            <div className={'aqi-measure-card'}>
                <Label className={'main-lbl'} >{this.props.lang.measure_heading}</Label>
                <div className={'main-div main-div-map-sec'} style={{ marginBottom: '21px' }}>
                    <Row>
                    {
                        dataSet.map((item, index) => {
                            return <Col lg={4} md={4} xl={6} >
                            <Row key={index} className={'measure-card'}>
                                <Col lg={4} md={4} xl={4} className={'left-div'}>
                                    <div>
                                        <div className={'value-div'}>{item.value}</div>
                                        {item.name === 'Temperature' || item.name === 'උෂ්ණත්වය' || item.name === 'வெப்பநிலை' ? <p>°C</p> : <p>μg/m3</p>}
                                    </div>
                                </Col>
                                <Col lg={8} md={8} xl={8} className={'right-div'}>
                                    <div className={'type-div'}>{item.name}{lan === config.ENGLISH ? "" : lan === config.SINHALA && item.name !== "උෂ්ණත්වය" ? "(අංශු)" : ""}</div>
                                    <img src={wind} width={'auto'} height={'25px'} />
                                    <div className={'view-lbl'} onClick={() => this.handleMoreDetail(item)}>{this.props.lang.viewmore}</div>
                                </Col>
                            </Row>
                            </Col>
                        })
                    }
                    </Row>
                </div>
                {
                    this.state.modal &&
                    <MoreDetail
                        close={() => this.handleMoreDetail()}
                        visibility={this.state.modal}
                        detail={this.state.modal_data}
                        deviceId={this.props.deviceId}
                        lang={this.props.lang}
                    />
                }
            </div>
        );
    }
}

export default AqiMeasures;
const langText = require('../components/lang/language.json');

const getLast7Days=()=>{
    var result = [];
    for (var i=7; i>0; i--) {
        var d = new Date();
        d.setDate(d.getDate() - 1);
        // var d = new Date();

        d.setDate(d.getDate() - (i - 1));

        var DD = d.getDate();
        var MMM = d.getMonth()+1;
        var yyyy = d.getFullYear();
        if(DD<10) {DD='0'+DD}
        if(MMM<10) {MMM='0'+MMM}
        d = DD+'/'+MMM+'/'+yyyy;

        result.push( d )
    }

    return result;
}


export {getLast7Days};

import React, { Component } from 'react';
import { Line } from "react-chartjs-2";
import "chartjs-plugin-annotation";
import *  as dateGenerator from '../../../utility/DateGenerator';
import * as config from '../../../config'
const langText = require('../../lang/language.json');



class LineChart extends Component {

    render() {
        let xData = this.props.days
        let YData = []

        if (config.PM2_TYPE === this.props.chartType) {
            if (this.props.data.length !== 0) {
                this.props.data.map((val) => {
                    if (val >= 300) {
                        YData.push(300)
                    } else {
                        YData.push(val)
                    }
                })
            }
        }else if (config.PM10_TYPE === this.props.chartType) {
            if (this.props.data.length !== 0) {
                this.props.data.map((val) => {
                    if (val >= 500) {
                        YData.push(500)
                    } else {
                        YData.push(val)
                    }
                })
            }
        }else if (config.TEMPERATURE_TYPE === this.props.chartType) {
            if (this.props.data.length !== 0) {
                this.props.data.map((val) => {
                    if (val >= 39.9) {
                        YData.push(39.9)

                    } else {
                        YData.push(val)
                    }
                })
            }
        }else if (this.props.type === "default" || this.props.data.length !== 0) {
            if (this.props.data.length !== 0) {
                this.props.data.map((val) => {
                    if (val >= 500) {
                        YData.push(500)
                    } else {
                        YData.push(val)
                    }
                })
            }
        }

        let dataset = [{
            label: "", data: YData, fill: false, borderColor: "#60157A", backgroundColor: "#60157A",
            pointBackgroundColor: "#ffffff"
        }]

        // if (this.props.type !== "default") {
        //     dataset.push({
        //         label: "",
        //         data: [200],
        //         fill: false,
        //         borderColor: 'transparent',
        //         backgroundColor: 'transparent',
        //         pointRadius: 0,
        //     })
        // }

        if (config.PM10_TYPE === this.props.chartType) {
            dataset.push({
                label: "",
                data: [500],
                fill: false,
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                pointRadius: 0,
            })
        }else if (config.PM2_TYPE === this.props.chartType) {
            dataset.push({
                label: "",
                data: [300], // change 350 to 300
                fill: false,
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                pointRadius: 0,
            })
        }else if (config.TEMPERATURE_TYPE === this.props.chartType) {
            dataset.push({
                label: "",
                data: [35],
                fill: false,
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                pointRadius: 0,
            })
        }else{
            dataset.push({
                label: "",
                data: [500],
                fill: false,
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                pointRadius: 0,
            })
        }

        const data = {
            labels: xData,
            datasets: dataset
        };

        let options = {
            // responsive:true,
            tooltips: {
                enabled: true,
                mode: 'single',
                callbacks: {
                    label: function (tooltipItems, data) {
                        return tooltipItems.yLabel + ' US AQI';
                    }
                }
            },
            scales: {
                yAxes: [{
                    scaleLabel: {
                        display: true,
                        // labelString: localStorage.getItem("lang") === null || localStorage.getItem("lang") === 'en' ?  langText.en.y_ :
                        //     localStorage.getItem("lang") === 'si' ? langText.si.y_ : langText.ta.y_,
                        // && config.PM10_TYPE === this.props.chartType ? config.PM10_YAXIS : config.PM2_TYPE === this.props.chartType ? config.PM2_YAXIS : config.TEMPERATURE_TYPE === this.props.chartType ? config.TEMPERATURE_YAXIS :
                        labelString: this.props.yAxisName ? this.props.yAxisName : localStorage.getItem("lang") === config.TAMIL ? "காற்றின் தரம்" : "Air Quality",
                        fontStyle: "bold",
                        fontFamily: "gilroy medium"
                    },
                    ticks: {
                        min: 0,
                        stepSize: this.props.chartType === config.TEMPERATURE_TYPE ? 10 : this.props.chartType === config.PM2_TYPE ? 50 : this.props.chartType === config.PM10_TYPE ? 50 : 50,
                    },
                    gridLines: {
                        display: false
                    }
                }],
                xAxes: [{
                    scaleLabel: {
                        display: true,
                        fontStyle: "bold",
                        fontFamily: "gilroy medium",
                        labelString: localStorage.getItem("lang") === null || localStorage.getItem("lang") === 'en' ? langText.en.x_ :
                            localStorage.getItem("lang") === 'si' ? langText.si.x_ : langText.ta.x_,
                    },
                    gridLines: {
                        display: false
                    },
                }]
            },
            legend: {
                display: false
            },
            annotation: {
                annotations: this.props.chartType === config.TEMPERATURE_TYPE ? config.ANNOTATION_TEMPERATURE : this.props.chartType === config.PM2_TYPE ? config.ANNOTATION_PM2 : this.props.chartType === config.PM10_TYPE ? config.ANNOTATION_PM10 : config.DEFAULT_ANNOTATION
            }
        }
        return (
            <Line datasetIdKey='id' datasetKeyProvider={datasetKeyProvider} data={data} options={options} height={window.screen.width < window.screen.height ? 500 : window.screen.width < 1400 ? 200 : 150} />
        );
    }
}
const datasetKeyProvider=()=>{
    return btoa(Math.random()).substring(0,12)
}
export default LineChart;

import React,{Component} from 'react';
import nbroImg from "../../../../assets/logo/NBRO/Group 32.png";
import vayuImg from "../../../../assets/logo/Vaayu Logo/Logo@2x.png";
import langImg from "../../../../assets/icon/lang.png";
import {Dropdown} from "semantic-ui-react";
import {Label} from "reactstrap";

import './style.scss';
import * as firebaseConstants from "../../../../config";

const languages = [
    {key: '1',text: 'English',value: 'en'},
    {key: '2',text: 'සිංහල',value: 'si'},
    {key: '3',text: 'தமிழ்',value: 'ta'}
];

class Header extends Component{



    onChangeLang=(e,data)=>{
        this.props.changeLanguage(data.value)
    };

    onChangeLocation=(e,data)=>{
        this.props.changeLocation(data.value, firebaseConstants.FIREBASE_EVENT_LOCATION_DROPDOWN);
    };


    render() {

        let deviceLocations=[];
        let language = this.props.lg;

        if (this.props.location.length !== 0) {
            this.props.location.map((value, index) => {
                return deviceLocations.push({
                    text: language === "en" ? value.location : language === "si" ? value.location_sin : value.location_tamil,
                    value: value.id,
                    key: value.id
                })
            })
        }


        return (
            <div className={'landingPg-header-div'}>
                <div className={'row'} id={'logo-div'}>
                    <div className='col-4' align="left">
                        <a href={'https://www.nbro.gov.lk/index.php?lang=en'} target='_blank'><img src={nbroImg} width={'76px'} alt={''}/></a>
                        <img src={vayuImg} width={'64px'} alt={''}/>
                    </div>
                    <div className='col-xl-4 col-lg-4 col-md-5'  align="center">
                        <Label className={'header-lbl'}>{this.props.lang.header_heading}</Label>
                        <div>
                            <Dropdown
                                fluid
                                selection
                                search
                                icon={'search'}
                                options={deviceLocations}
                                value={this.props.locationId}
                                onChange={this.onChangeLocation}
                            />
                        </div>
                    </div>
                    <div className='col-xl-4 col-lg-4 col-md-3' align="right" style={{paddingTop:"3px"}}>
                        {/*<img src={langImg} style={{paddingRight:'12px'}} alt={''}/>*/}
                        <Dropdown
                            inline
                            options={languages}
                            value={language}
                            onChange={this.onChangeLang}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default Header;
import React, { Component } from 'react';
import './App.css';
// import LandingPage from './views/LandingPage/index';
import LandingPage from './views/revamp/landingPage/LandingPage';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
// import MobileView from './views/MobileView/index';
import MobileView from './views/revamp/mobileView/MobileView';
// import Loading from "./components/Loading/Loading";


class App extends Component {
  state = {
    mobileView : 0
  }
  componentDidMount() {
    this.resizeFunction();
    // window.addEventListener("resize", this.resizeFunction);
  }

  resizeFunction = () => {
    // 540
    if (window.innerWidth <= 1000) {
      this.setState({ mobileView: 1 });
    }else{
      this.setState({ mobileView: 2 });
    }
  };


  render(){
    return (
      //   <div className="App">
      //     <LandingPage/>
      //   </div>
      //   path="/uiOertyLju"

        <BrowserRouter>
           {/* <Router> */}
           {/*<Loading/>*/}
              <Switch>
              {
                this.state.mobileView === 2 ?
                <Route  path="" name="Home" component={LandingPage} /> :
                    this.state.mobileView === 1 ? <Route  path="" name="MobileHome" component={MobileView} />
                        :null
              }

              </Switch>
             {/* </Router>  */}
        </BrowserRouter>
      );
  }

}

export default App;
